import Slideout from 'slideout';

let options = {
    'panel': document.getElementById('panel'),
    'menu': document.getElementById('menu'),
    'padding': 256,
    'tolerance': 70
}
let navigation = new Slideout(options)

let toggles = Array.from(document.getElementsByClassName('menu-toggle'));
toggles.map(toggle => {
    toggle.addEventListener('click', () => {
        navigation.toggle()
    })
})